import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { getScanParameters, getLinkToCamera } from '../utils/qr-code-scanner.ts';
import { task, timeout } from 'ember-concurrency';

import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type EnvSettingsService from '@adc/app-infrastructure/services/env-settings';
import type { TextInputSignature } from './text-input.ts';
import type { SCAN_TYPE, ScanParameters } from '../utils/qr-code-scanner.ts';

type TextInputSignatureArgs = TextInputSignature['Args'];

interface QrCodeScannerSignature {
    Element: HTMLInputElement;
    Args: Pick<TextInputSignatureArgs, 'value-change' | 'value' | 'type'> & {
        /** Indicates the input is required. */
        required?: boolean;
        /** Text to be shown when value is empty. */
        placeholder?: string;
        /** The name attribute for the input. */
        name?: string;
        /** The pattern attribute specifies a regular expression that the input element's value is checked against. */
        pattern?: string;
        /** The html id of the `<input/>` element that will retrieve that qrcode value. */
        textFieldId?: string;
        /** Determines what type of code we want to scan. */
        scanCodeType?: 'qr' | 'bar' | 'both';
        /** Indicates QR scanning can be done anywhere. */
        isScanFeatureAvailable?: boolean;
        /** Indicates QR scanning can be done if isMobile also returns true. */
        isScanFeatureAvailableForAppOnly?: boolean;
        /** If using this component on the spark app. */
        isMobileTech?: boolean;
        /** If the input should be disabled. */
        disabled?: boolean;
        /** Action that get's called when component is created */
        didInsert?: () => void;
        /** Action that get's called when component is destroyed */
        willDestroy?: () => void;
    };
}

/**
 * The translation keys for the scan code types.
 */
const TRANSLATION_KEYS = {
    both: 'scannerBoth',
    bar: 'scannerBar',
    qr: 'scannerQR'
};

/**
 * The timeout (in ms) used when polling to see if there are changes to the input element.
 */
const POLLING_INTERVAL_MS = 500;

/**
 * @classdesc
 *
 * A component consisting of a <TextInput/> and <SvgSymbol/> component.
 * The svg symbol redirects the user to a qrcode scanner in the mobile code.
 * Once the scanner fetches a value, the value is placed into the <input /> element inside <TextInput/>.
 *
 * NOTE: the scanner in mobile code works for both barcode and qrcode.
 */
export default class QrCodeScanner extends Component<QrCodeScannerSignature> {
    @service declare intl: ADCIntlService;
    @service declare envSettings: EnvSettingsService;

    /**
     * Id for input element that will receive QR code value.
     * NOTE: textFieldId property is the custom html id given to <Input/> tag within <TextInput /> component.
     * This is necessary because the mobile qr scanner requires a specific element ID of an input element.
     */
    get textFieldId(): string {
        return this.args.textFieldId ?? 'qrcontent-input';
    }

    /**
     * Returns intent to be used for the barcode scanner based on value of 'isMobileTech'.
     */
    get barcodeLocation(): string {
        return getBarcodeLocation(this.args.isMobileTech ?? false);
    }

    /**
     * Returns scan parameters based on what was specified in the 'scanCodeType'.
     */
    get scanParameters(): ScanParameters {
        return buildScanParameters(this.args.scanCodeType ?? 'qr', this.intl);
    }

    /*
     *  Returns true if user is on mobile (Currently only supports mobiletech app)
     */
    get isMobile(): boolean {
        return isMobileTechApp();
    }

    /**
     * Task that checks for changes to the input element.
     * This is needed because we cannot detect when the mobile device updates the input with the scanned value.
     */
    checkForInputChangesTask = task({ drop: true }, async (el: HTMLInputElement) => {
        if (this.envSettings.isTestEnvironment() || !this.args['value-change']) {
            return;
        }

        for (;;) {
            await timeout(POLLING_INTERVAL_MS);
            const currentValue = el.value || '',
                previousValue = this.args.value || '';
            if (currentValue === previousValue) {
                continue;
            }

            this.args['value-change'](currentValue);
        }
    });

    /**
     * Action to redirect to scanner in mobile app.
     */
    @action redirectToQrCameraApp(): void {
        window.location.href = getLinkToCamera(this.textFieldId, this.scanParameters, this.barcodeLocation);
    }
}

/**
 * Determines whether or not user is using the mobiletech app
 * currently only supports mobiletech
 */
function isMobileTechApp(): boolean {
    const userAgent = navigator.userAgent;

    return /MOBILE\s*TECH/i.test(userAgent);
}

/**
 * Builds a `ScanParameters` object given a `scanCodeType`.
 * This is used by the qr/barcode scanner feature in the customer mobile app.
 */
function buildScanParameters(scanCodeType: SCAN_TYPE, intl: ADCIntlService): ScanParameters {
    const translationKey = TRANSLATION_KEYS[scanCodeType];

    return getScanParameters(scanCodeType, intl.t(`@adc/ui-components.${translationKey}`));
}

/**
 * Gets an intent string representing a barcode location based on `isMobileTech`.
 * This is used by the qr/barcode scanner feature in the customer mobile app.
 */
function getBarcodeLocation(isMobileTech: boolean): string {
    return isMobileTech
        ? 'com.alarm.alarmmobile.android.tools.mobiletech#Intent;action=com.alarm.alarmmobile.android.mobiletech.BARCODE;'
        : '#barcode;action=com.alarm.alarmmobile.android.BARCODE;';
}

export { isMobileTechApp, buildScanParameters, getBarcodeLocation };
