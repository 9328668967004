import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';
import { htmlSafe } from '@ember/template';
import { computed, action, set, getProperties, setProperties } from '@ember/object';
import { bool, or, empty } from '@ember/object/computed';
import { once, scheduleOnce } from '@ember/runloop';
import { guidFor } from '@ember/object/internals';
import { A } from '@ember/array';
import CheckboxTreeItem, { SELECTED, UNSELECTED, getAllSelectedItems } from '../../utils/dropdown-select-item.js';
import { task } from 'ember-concurrency';

import type { SafeString } from 'handlebars';
import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type { BaseDropDownWrapperSignature } from '../base-dropdown/wrapper';
import type { CommonInputErrorTooltipArgs } from '../error-tooltip';
import type { DropdownMultiSelectToolbarSignature } from './multi-select/toolbar';
import type { CheckboxTreeSignature } from '../checkbox-tree';
import type { Task } from 'ember-concurrency';

type BaseDropDownWrapperSignatureArgs = BaseDropDownWrapperSignature['Args'];
type DropdownMultiSelectToolbarSignatureArgs = DropdownMultiSelectToolbarSignature['Args'];

export class ChecklistTreeInfo {
    title: string;
    items: CheckboxTreeItem[];
    maxItemCount: number;
    additionalItemCount: number;
    show: boolean;
    noResults: boolean;
    showHeader: boolean;
    showAllNoneSelectors: boolean;

    constructor(props: Partial<ChecklistTreeInfo> = {}) {
        this.title = props.title ?? '';
        this.items = props.items ?? [];
        this.maxItemCount = props.maxItemCount ?? 0;
        this.additionalItemCount = props.additionalItemCount ?? 0;
        this.show = props.show ?? true;
        this.noResults = props.noResults ?? false;
        this.showHeader = props.showHeader ?? false;
        this.showAllNoneSelectors = props.showAllNoneSelectors ?? false;
    }

    get displayItems(): CheckboxTreeItem[] {
        const { maxItemCount, items } = this;
        return maxItemCount > 0 ? items.slice(0, maxItemCount) : items;
    }

    private updateItemState(state: number): void {
        A(this.items.filter((item) => item.isSelectable && !item.disabled)).setEach('state', state);
    }

    @action selectAll(): void {
        this.updateItemState(SELECTED);
    }

    @action selectNone(): void {
        this.updateItemState(UNSELECTED);
    }
}

const VALUE_CHANGE_ACTION = 'value-change';

/**
 * Sets the selector of the focusable element.
 */
function setFocusSelector(this: MultiSelectComponent, elementId?: string): void {
    const fnUpdateFocusSelector = (s = ''): void => {
        set(this, 'focusSelector', `.dropdown-content div[data-dropdown-id='${guidFor(this)}']${s}`);
    };

    if (elementId) {
        return fnUpdateFocusSelector(`.dropdown-body input[id='${elementId}']`);
    }

    if (this.showSearch) {
        return fnUpdateFocusSelector('.toolbar input[type="search"]');
    }

    return fnUpdateFocusSelector();
}

/**
 * Sets focus back to the trigger of the dropdown if it is closed.
 */
function setFocusBackToTrigger(this: MultiSelectComponent) {
    const triggerElement = (
        this.hasCustomTrigger
            ? document.querySelector(
                  `.external-trigger .dropdown-select button[id='${this.triggerId}'].dropdown-trigger div`
              )?.firstElementChild
            : document.querySelector(`.dropdown-select button[aria-owns='${this.contentId}'].btn-select`)
    ) as HTMLElement;

    if (!this.isOpen && triggerElement) {
        triggerElement.focus();
    }
}

/**
 * Schedules clearing of error message
 */
function clearErrorMessageTrigger(this: MultiSelectComponent): void {
    // Do not copy this deprecated usage. If you see this, please fix it
    // eslint-disable-next-line ember/no-runloop
    scheduleOnce('afterRender', this, 'clearErrorMessage');
}

/**
 * Clones the passed items.
 *
 * @param items The items to clone.
 * @param [flatten=false] If true, subitems will be flattened into the same array as their parent.
 */
function cloneItems(items: CheckboxTreeItem[] = [], flatten = false): CheckboxTreeItem[] {
    return items.reduce((items, src) => {
        const subItems = cloneItems(src.subitems, flatten),
            { parent, description } = src;

        return [
            ...items,
            CheckboxTreeItem.create({
                ...getProperties(
                    src,
                    'name',
                    'value',
                    'disabled',
                    'icon',
                    'state',
                    'action',
                    'isSelectable',
                    'isCollapsible',
                    'showAllNoneSelectors'
                ),
                description: parent ? `(${parent.name}) ${description}` : description,
                subitems: flatten ? [] : subItems,
                src
            })
        ];
    }, []);
}

/**
 * Synchronizes cloned items with their source item.
 *
 * @param clones The cloned items to synchronize.
 */
function synchronizeItems(clones: CheckboxTreeItem[]) {
    // Iterate clones.
    clones.forEach((clone) => {
        const { src } = clone;

        // Is there a source for this clone?
        if (src) {
            // Update state and synchronize subitems.
            src.set('state', clone.state);
            synchronizeItems(clone.subitems);
        }
    });
}

/**
 * Returns a flattened array of items that match the provided search text.
 *
 * @param items - The items we are matching the search text against.
 * @param searchString - The search string.
 */
function getFilteredItems(items: CheckboxTreeItem[], searchString: string): CheckboxTreeItem[] {
    if (!searchString) {
        return items;
    }

    return items.reduce(
        (filteredItems, item) => [
            ...filteredItems,
            ...(item.hasSubitems ? getFilteredItems(item.subitems, searchString) : [])
        ],
        items.filter(doesItemMatchSearch.bind(null, searchString))
    );
}

/**
 * Returns true if the item's properties contain the searchString.
 *
 * @param searchString - The text we are searching for.
 * @param item - The item we are testing the search text against.
 */
function doesItemMatchSearch(searchString: string, item: CheckboxTreeItem): boolean {
    return ['name', 'description', 'secondaryDescription'].some((property) =>
        (item[property] || '').toLowerCase().includes(searchString.toLowerCase())
    );
}

type ItemsCollection = {
    limit: number;
    items: CheckboxTreeItem[];
};

export interface MultiSelectSignature {
    Element: HTMLDivElement;
    Args: CommonInputErrorTooltipArgs &
        Pick<
            BaseDropDownWrapperSignatureArgs,
            | 'popoverPlacement'
            | 'popoverClass'
            | 'preventScroll'
            | 'popoverMaxHeight'
            | 'popoverMaxWidth'
            | 'popoverMinWidth'
            | 'matchTriggerElementWidth'
        > &
        Pick<DropdownMultiSelectToolbarSignatureArgs, 'placeholder'> &
        Pick<CheckboxTreeSignature['Args'], 'isFocusable'> & {
            /** The items to render. */
            items: CheckboxTreeItem[] | Promise<CheckboxTreeItem[]>;
            /** Describes the type of items displayed. */
            itemsText?: string;
            /** Indicates we should use the `{{yield}}` template helper to render a custom trigger for this dropdown. */
            hasCustomTrigger?: boolean;
            /** Indicates the dropdown is open. */
            isOpen?: boolean;
            /** Indicates the dropdown search box should be shown, regardless of the number of items present.  */
            hasSearch?: boolean;
            /** Indicates the maximum number of items to display before showing search box. */
            maxItemCount?: number;
            /** Always show the "Please Select All | None" header, even when items are not limited via maxItemCount. */
            alwaysShowHeaders?: boolean;
            /** The search text. */
            searchString?: string;
            /** Triggered when the user chooses a new value for the drop down. */
            'value-change'?: (items: CheckboxTreeItem[]) => void;
            /** The aria-label value for the trigger */
            label?: string;
            /** `-1` to indicate non-custom trigger is keyboard accessible. */
            tabindex?: number;
            /** Indicates the non-custom trigger should be disabled. */
            disabled?: boolean;
        };
    Blocks: {
        /** When `@hasCustomTrigger` is `true` this will yield the `id` and CSS classes for the customer trigger, as well as an action for toggling the drop down open/closed. */
        default: [string, string, (event: Event) => void];
    };
}

/**
 * @classdesc
 * A custom dropdown (i.e. "select") allowing multiple items to be selected.
 *
 * @note This component should not be directly rendered in a template. Use dropdown-select
 *       with the "multiselect" property set to true in order to use this.
 */
export default class MultiSelectComponent extends Component<MultiSelectSignature> {
    tagName = '';

    @service declare intl: ADCIntlService;

    constructor(...args: any[]) {
        super(...args);

        this.updateFocusSelector();
    }

    /**
     * The passed dropdown items.
     */
    items: CheckboxTreeItem[] | Promise<CheckboxTreeItem[]> = [];

    /**
     * The number of items shown before we begin hiding items and force a search to narrow results.  Any value less than 1 means we will
     * show all items with no search functionality.
     */
    maxItemCount = -1;

    /**
     * Indicates we should always show the "Please Select    All | None" header, even when items are not limited via `maxItemCount`.
     */
    alwaysShowHeaders = false;

    /**
     * Searching is enabled automatically if max item count is exceeded, but if this property is true the search will
     * be displayed even if there aren't too many items.
     */
    hasSearch = false;

    /**
     * The dropdown select placeholder.
     */
    placeholder = '';

    /**
     * The text to show in the search box.
     */
    searchString = '';

    /**
     * Used to describe the items display in the select box.
     */
    itemsText = '';

    /**
     * Indicates we should use the yield helper to display a custom trigger.
     */
    hasCustomTrigger = false;

    /**
     * When the popover for this is rendered, we trigger a focus event on the popover.  Should we prevent the page from scrolling when this focus is triggered.
     */
    preventScroll = false;

    /**
     * A copy of the last selected items.
     */
    cachedSelections: CheckboxTreeItem[] = [];

    /**
     * A closure function to call when the selected item changes.
     */
    [VALUE_CHANGE_ACTION]?: (value: CheckboxTreeItem[]) => void;

    /**
     * Specifies error message to be displayed as a tooltip on the input.
     */
    errorMessage: string | undefined;

    /**
     * Indicates the dropdown is open.
     */
    @tracked isOpen = false;

    /**
     * The selector of the element which should receive focus after the dropdown opens.
     */
    focusSelector = '';

    /**
     * The checkbox items to render (after promise is resolved).
     */
    @tracked resolvedItems: CheckboxTreeItem[] = [];

    /**
     * A task for resolving promise items.
     */
    resolveItems: Task<void, never> = task({ restartable: true }, async () => {
        const items = await this.items;
        this.resolvedItems = items ? [...items] : [];
    });

    @empty('resolvedItems')
    declare isEmpty: boolean;

    /**
     * Indicates we should show the checklist header with title and all/none selectors.
     */
    @computed('resolvedItems.@each.{isSelectable,isCollapsible}')
    get isSimpleList(): boolean {
        const resolvedItems = A(this.resolvedItems);
        return resolvedItems.isEvery('isSelectable', true) && resolvedItems.isEvery('isCollapsible', false);
    }

    /**
     * Indicates whether we should limit the displayed items and show search/selected items.
     */
    @computed('resolvedItems.[]', 'maxItemCount')
    get limitItems(): boolean {
        const { maxItemCount } = this;
        return maxItemCount > 0 && this.resolvedItems.length > maxItemCount;
    }

    /**
     * Indicates the search box should be visible.
     */
    @or('limitItems', 'hasSearch')
    showSearch!: boolean;

    /**
     * Are we displaying a subset of items because of the search string filter?
     */
    @bool('searchString')
    isFiltered!: boolean;

    /**
     * The raw items that are currently selected.
     */
    @computed('resolvedItems.@each.{state,isFullySelected,isPartiallySelected,isNotSelected}')
    get selectedItems(): CheckboxTreeItem[] {
        return getAllSelectedItems(this.resolvedItems);
    }

    /**
     * The raw items that are currently not selected.
     */
    @computed('resolvedItems.@each.state')
    get unselectedItems(): CheckboxTreeItem[] {
        return A(this.resolvedItems).rejectBy('state', SELECTED);
    }

    /**
     * Information about selected and unselected items, including computed limits for each.
     */
    @computed('isSimpleList', 'maxItemCount', 'resolvedItems.length', 'selectedItems.length', 'unselectedItems.length')
    get itemCollections(): {
        selected: ItemsCollection;
        unselected: ItemsCollection;
    } {
        const maxItemCount = Number(this.maxItemCount),
            { selectedItems, unselectedItems, resolvedItems, isSimpleList } = this,
            selected = {
                limit: 0,
                items: cloneItems(selectedItems, true)
            },
            unselected = {
                limit: 0,
                items: cloneItems(unselectedItems, false)
            };

        // Do we need to limit items?
        if (isSimpleList && maxItemCount > 0 && resolvedItems.length > maxItemCount) {
            const selectedCount = selectedItems.length,
                unselectedCount = unselectedItems.length;

            if (!selectedCount) {
                unselected.limit = maxItemCount;
            } else if (!unselectedCount) {
                selected.limit = maxItemCount;
            } else {
                const selectedMax = Math.floor(maxItemCount / 2);

                if (unselectedCount <= selectedMax) {
                    selected.limit = maxItemCount - unselectedCount;
                    unselected.limit = unselectedCount;
                } else if (selectedCount <= selectedMax) {
                    selected.limit = selectedCount;
                    unselected.limit = maxItemCount - selectedCount;
                } else {
                    selected.limit = selectedMax;
                    unselected.limit = maxItemCount - selectedMax;
                }
            }
        }

        // Are there no limits to the number of items shown.
        if (!isSimpleList || (selected.limit === 0 && unselected.limit === 0)) {
            // Don't use clones and don't show selected items.
            selected.items = [];
            unselected.items = resolvedItems;
        }

        return { selected, unselected };
    }

    /**
     * Creates and returns an array of CheckboxTreeItem for the passed items.
     */
    private getCheckList(title: string, itemCollection: ItemsCollection): ChecklistTreeInfo {
        const { items, limit } = itemCollection;

        if (isEmpty(items)) {
            // Return empty results.
            return new ChecklistTreeInfo({
                show: false
            });
        }

        const { searchString } = this,
            filteredItems = this.showSearch ? getFilteredItems.call(this, items, searchString) : items;

        // Are results filtered AND were none found?
        if (searchString && isEmpty(filteredItems)) {
            // Return no results.
            return new ChecklistTreeInfo({
                title,
                showHeader: true,
                noResults: true
            });
        }

        return new ChecklistTreeInfo({
            title,
            items: filteredItems,
            maxItemCount: limit,
            additionalItemCount: limit && filteredItems.length > limit ? filteredItems.length - limit : 0,
            showHeader: this.isSimpleList && (this.limitItems || this.alwaysShowHeaders),
            showAllNoneSelectors: true
        });
    }

    /**
     * The checklist tree that represents the currently selected items.
     */
    @computed('itemCollections.selected', 'itemsText', 'searchString', 'showSearch')
    get selectedTree(): ChecklistTreeInfo {
        return this.getCheckList(
            this.intl.t('@adc/ui-components.selectedItems', {
                itemsText: this.itemsText
            }),
            this.itemCollections.selected
        );
    }

    /**
     * The checklist tree that represents the currently unselected items.
     */
    @computed('itemCollections.unselected', 'searchString', 'showSearch')
    get unselectedTree(): ChecklistTreeInfo {
        return this.getCheckList(this.intl.t('@adc/ui-components.pleaseSelect'), this.itemCollections.unselected);
    }

    /**
     * A unique ID for the dropdown trigger to correspond to the content (for ARIA purposes).
     */
    @computed()
    get triggerId(): string {
        return `dropdown-trigger-${guidFor(this)}`;
    }

    /**
     * A unique ID so the dropdown trigger can correspond to the content (for ARIA purposes).
     */
    @computed()
    get contentId(): string {
        return `dropdown-content-${guidFor(this)}`;
    }

    /**
     * The computed text for the trigger.
     */
    @computed('selectedItems', 'placeholder')
    get triggerText(): string {
        return (
            this.selectedItems
                .map(({ isFullySelected, hasSubitems, name }) =>
                    isFullySelected && hasSubitems
                        ? this.intl.t('@adc/ui-components.allItems', {
                              items: name
                          })
                        : name
                )
                .join(', ') || this.placeholder
        );
    }

    /**
     * The computed text to show in the dropdown trigger.
     */
    @computed('triggerText')
    get triggerTextHtml(): SafeString {
        // Return within a span element so we can manipulate it (if too long) and not break the binding.
        return htmlSafe(`<span>${this.triggerText}</span>`);
    }

    /**
     * Is the placeholder currently shown as the trigger text?
     *
     * @note Conditionally adds the "placeholder" class so the CSS can modify the placeholder text color.
     *
     * @ignore
     */
    @empty('selectedItems')
    isPlaceholderShown!: boolean;

    /**
     * Determines if the selected items have changed.
     */
    hasSelectionChanged(currentSelections: CheckboxTreeItem[], previousSelections: CheckboxTreeItem[]): boolean {
        return (
            currentSelections.length !== previousSelections.length ||
            currentSelections.some(({ value, state, hasSubitems, subitems }) => {
                // Was this item not present in previous selections?
                const previousItem = A(previousSelections).findBy('value', value);
                if (!previousItem) {
                    // Selection changed.
                    return true;
                }

                // Are there sub items?
                if (hasSubitems) {
                    // Compare subitems.
                    return this.hasSelectionChanged(subitems, previousItem.subitems);
                }

                // Compare items.
                return previousItem.state !== state;
            })
        );
    }

    /**
     * Updates the calling instance via the change action function , if the selected values have changed.
     */
    saveSelectionChange(
        currentValue: CheckboxTreeItem[],
        previousValue: CheckboxTreeItem[],
        valueChangeParameter: CheckboxTreeItem[] = currentValue
    ): void {
        if (this.hasSelectionChanged(currentValue, previousValue)) {
            clearErrorMessageTrigger.call(this);

            const valueChangeAction = this[VALUE_CHANGE_ACTION];
            if (valueChangeAction) {
                valueChangeAction(valueChangeParameter);
            }
        }
    }

    /**
     * Called when the dropdown trigger element changes size.
     */
    @action triggerResized(button: HTMLButtonElement): void {
        this.triggerTextChanged(button);
    }

    /**
     * Called when the text with the dropdown trigger element changes.
     * Modifies the trigger text to ensure it fits within the trigger.
     */
    @action triggerTextChanged(button: HTMLButtonElement): void {
        const label = button.querySelector('span');

        // Is label missing or empty?
        if (!label || isEmpty(label.innerText)) {
            // Nothing to do.
            return;
        }

        // Is the label span larger than the parent span (minus 60px for arrow)?
        // This updates the trigger aria-label, also
        if (
            label.getBoundingClientRect().width >=
                (button.parentNode as HTMLElement).getBoundingClientRect().width - 60 &&
            !(this.isDestroyed || this.isDestroying)
        ) {
            label.innerText = `${this.selectedItems.length} ${this.itemsText || this.placeholder}`;
            button.setAttribute('aria-label', label.innerText);
        }
    }

    /**
     * Updates the focus selector.
     */
    @action updateFocusSelector(): void {
        // Do not copy this deprecated usage. If you see this, please fix it
        // eslint-disable-next-line ember/no-runloop
        once<MultiSelectComponent, typeof setFocusSelector>(this, setFocusSelector, undefined);
    }

    /**
     * Updates the focus selector to select the active element.
     */
    @action updateFocusSelectorForElement(): void {
        const activeElement = document.activeElement as HTMLElement;
        if (activeElement?.dataset) {
            setFocusSelector.call(this, activeElement.id);
        }
    }

    /**
     * Caches the selected items when the drop down opens.
     */
    @action onDropdownOpen(): void {
        // Cache the items selected at time of open.
        this.cachedSelections = cloneItems(this.selectedItems, true);
    }

    /**
     * Called when the multiselect dropdown closes, to update selected items.
     */
    @action onDropdownClose(): void {
        // Synchronize items.
        [this.selectedTree, this.unselectedTree].forEach((tree) => synchronizeItems(tree.items));

        this.saveSelectionChange(this.selectedItems, this.cachedSelections, this.resolvedItems);

        // Reset.
        setProperties(this, {
            isOpen: false,
            searchString: ''
        });

        setFocusBackToTrigger.call(this);
    }

    @action clearErrorMessage(): void {
        set(this, 'errorMessage', undefined);
    }

    @action togglePopoverVisibility(event: Event): void {
        this.isOpen = !this.isOpen;
        event.stopPropagation();
    }
}
