import templateOnlyComponent from '@ember/component/template-only';

export interface NoResultsContentSignature {
    Args: {
        /** Indicates the route-view model resolved to have no results */
        hasNoResults?: boolean;
        /** I can't find anywhere this is used. */
        search?: string;
        /** The type of item searched for. */
        typeText?: string;
        /** Triggered when this component is done rendering. */
        markEndOfRendering?: () => void;
    };
    Blocks: {
        default: [];
    };
}

const RouteViewViewContainerNoResultsContent = templateOnlyComponent<NoResultsContentSignature>();
export default RouteViewViewContainerNoResultsContent;
