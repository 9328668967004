import templateOnlyComponent from '@ember/component/template-only';

import type { SearchInputSignature } from './search-input';

type SearchInputSignatureArgs = SearchInputSignature['Args'];

export interface SearchToolbarSignature {
    Element: SearchInputSignature['Element'];
    Args: {
        /** The current search value. */
        searchString?: SearchInputSignatureArgs['value'];
        /** Triggered when the search string changes. */
        searchValueChange?: SearchInputSignatureArgs['value-change'];
        /** Optional CSS class for the search toolbar container element. */
        containerClass?: string;
        /** Indicates the search box should be hidden. */
        searchBoxHidden?: boolean;
        /** Indicates the search box should be disabled. */
        searchBoxDisabled?: boolean;
        /** Optional `aria-label` value. */
        ariaLabel?: SearchInputSignatureArgs['ariaLabel'];
    };
    Blocks: {
        default: [];
    };
}
const SearchToolbar = templateOnlyComponent<SearchToolbarSignature>();
export default SearchToolbar;
