import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { isEmpty, isPresent } from '@ember/utils';
import { isArray, A } from '@ember/array';
import { capitalize } from '@ember/string';
import DropdownSelectItem from '../utils/dropdown-select-item.js';
import { inject as service } from '@ember/service';

import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type { SingleSelectSignature } from './dropdown-select/single-select';
import type { MultiSelectSignature } from './dropdown-select/multi-select';
import type { CommonInputErrorTooltipArgs } from './error-tooltip';

type MultiSelectSignatureArgs = MultiSelectSignature['Args'];
type SingleSelectSignatureArgs = SingleSelectSignature['Args'];
type Items<TItem> = TItem[] | DropdownSelectItem[];

interface DropdownSelectCommonArgs<TItem> extends CommonInputErrorTooltipArgs {
    /** The Drop down items to render. */
    items: Promise<Items<TItem>> | Items<TItem>;
    /** The current select value (only works for single select). */
    value?: string;
    /** The key of the item used for the item value. */
    itemValueKey?: keyof TItem;
    /** The key of the item used for the item name. */
    itemNameKey?: keyof TItem;
    /** The key of the item used for the item description. */
    itemDescriptionKey?: keyof TItem;
    /** The key of the item used for the item disabled state. */
    itemDisabledKey?: keyof TItem;
}

interface DropdownSelectSingleArgs<TItem>
    extends DropdownSelectCommonArgs<TItem>,
        Pick<
            SingleSelectSignatureArgs,
            'value-change' | 'label' | 'selectId' | 'placeholder' | 'disabled' | 'required'
        > {
    /** Indicates the dropdown should be multi-select. */
    multiselect?: false;
    /** The tab index value for the native select element. */
    tabindex?: number;
}

interface DropdownSelectMultiArgs<TItem>
    extends DropdownSelectCommonArgs<TItem>,
        Pick<
            MultiSelectSignatureArgs,
            | 'popoverPlacement'
            | 'popoverClass'
            | 'preventScroll'
            | 'popoverMaxHeight'
            | 'popoverMaxWidth'
            | 'popoverMinWidth'
            | 'matchTriggerElementWidth'
            | 'itemsText'
            | 'isOpen'
            | 'alwaysShowHeaders'
            | 'maxItemCount'
            | 'hasSearch'
            | 'searchString'
            | 'label'
            | 'placeholder'
            | 'disabled'
            | 'tabindex'
            | 'value-change'
        > {
    /** Indicates the dropdown should be multi-select. */
    multiselect: true;
    /** The popover max height, in pixels. */
    maxHeight?: MultiSelectSignatureArgs['popoverMaxHeight'];
    /** The popover max width, in pixels. */
    maxWidth?: MultiSelectSignatureArgs['popoverMaxWidth'];
    /** The popover minimum width, in pixels. */
    minWidth?: MultiSelectSignatureArgs['popoverMinWidth'];
}

interface DropdownSelectSignature<TItem> {
    Element: SingleSelectSignature['Element'] | MultiSelectSignature['Element'];
    Args: DropdownSelectMultiArgs<TItem> | DropdownSelectSingleArgs<TItem>;
    Blocks: {
        /** When passing a custom trigger (multiselect only) this will yield the `id` and CSS classes for the customer trigger, as well as an action for toggling the drop down opened/closed. */
        default: [string, string, (event: Event) => void];
    };
}

/**
 * @classdesc
 *
 * A middle-man component for rendering either a native HTML select element, or a custom multiselect dropdown list.
 */
export default class DropdownSelect<TItem> extends Component<DropdownSelectSignature<TItem>> {
    @service declare intl: ADCIntlService;

    /**
     * Whether or not the dropdown wrapper should match the trigger element width, with respect to the min-width CSS property value.
     */
    get matchTriggerElementWidth(): boolean {
        const { args } = this;
        return (args.multiselect && args.matchTriggerElementWidth) ?? true;
    }

    /**
     * Converts a custom object into a {@link DropdownSelectItem} using the property keys (e.g. itemNameKey).
     */
    private convertToDropdownSelectItem(customItem: TItem): DropdownSelectItem {
        const newItemObject: Partial<DropdownSelectItem> = {};

        type CustomKeyArgs = Pick<
            DropdownSelectSignature<TItem>['Args'],
            'itemNameKey' | 'itemValueKey' | 'itemDescriptionKey' | 'itemDisabledKey'
        >;
        ['name', 'value', 'description', 'disabled'].forEach((dropdownSelectKey) => {
            const customKey = this.args[`item${capitalize(dropdownSelectKey)}Key` as keyof CustomKeyArgs];

            // Was a key defined and does the custom object have a value for that key?
            if (customKey && isPresent(customItem[customKey])) {
                newItemObject[dropdownSelectKey] = customItem[customKey];
            }
        });

        return DropdownSelectItem.create(newItemObject as DropdownSelectItem);
    }

    /**
     * Transforms the items resolved in the promise into the correct format for use in the dropdown-select.
     *
     * @note This allows a developer to define a mapping and then have the dropdown-select component handle the
     *       conversion once the promise resolves.
     */
    private transformItemsCollection(items: Items<TItem>): DropdownSelectItem[] {
        // NOTE: The order of checks is important here. The order of precedence is:
        // 1. Value and name mapping
        // 2. No mapping (return the same array)

        if (!isArray(items) || isEmpty(items)) {
            return A([]);
        }

        // Is there a known mapping of value and name?
        if (this.args.itemValueKey && this.args.itemNameKey) {
            // Create a DropdownSelectItem for each item returned from the promise.
            return A((items as TItem[]).map((item) => this.convertToDropdownSelectItem(item)));
        }

        // No mapping was specified. Return the same array.
        return items as DropdownSelectItem[];
    }

    /**
     * Ensures that "items" are valid, are run through any specified transforms, and are wrapped in a PromiseArray.
     */
    @computed('args.items.[]')
    get itemsInternal(): Promise<DropdownSelectItem[]> {
        return (async () => {
            const items = await this.args.items,
                transformedItems = this.transformItemsCollection(items);

            // Does each item have the correct DropdownSelectItem structure?
            transformedItems.forEach((item) => {
                if (!item || item.name === undefined || item.value === undefined) {
                    throw new Error(`Item must have value and name properties ${JSON.stringify(item)}`);
                }
            });

            return transformedItems;
        })();
    }

    /**
     * A string copy of the value since it must be a string for data attribute lookup in the DOM.
     */
    @computed('args.value')
    get valueString(): string | undefined {
        const { value } = this.args;
        return isEmpty(value) ? value : String(value);
    }
}
