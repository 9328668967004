import Component from '@glimmer/component';
import FulfilledContent from '../route-view/content/fulfilled-content.ts';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { reads } from '@ember/object/computed';
import { task, timeout } from 'ember-concurrency';
import { isArray } from '@ember/array';
import { assert } from '@ember/debug';

import type PerformanceMonitorService from '@adc/app-infrastructure/services/performance-monitor';
import type RouterService from '@ember/routing/router-service';
import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type { ComponentLike, WithBoundArgs } from '@glint/template';
import type PendingContent from '../route-view/content/pending-content';
import type { NoResultsContentSignature } from '../route-view/view-container/no-results-content';
import type { ErrorContentSignature } from '../route-view/content/error-content';
import type { TEmberAjaxError } from '@adc/ajax/services/adc-ajax';
import type { Task } from 'ember-concurrency';

const CONTENT_WIDTHS = ['small', 'large', 'full'] as const;
type ContentWidth = (typeof CONTENT_WIDTHS)[number];
type ContentWidthClass = 'small-width' | 'large-width' | 'full-width' | '';

type ResolvedModel<M> = NonNullable<Awaited<Promise<M> | M>>;

export interface PageViewContainerSignature<M> {
    Element: HTMLDivElement;
    Args: Pick<NoResultsContentSignature['Args'], 'search' | 'typeText'> & {
        /** The model for this page view container, which will be resolved and passed to the yielded fulfilled content component. */
        model?: Promise<M> | M;
        /** Removes the default CSS styling for this component. */
        noDefaultStyling?: boolean;
        /** The width of the content. */
        contentWidth?: ContentWidth;
        /** Triggered when the `@model` has been resolved. */
        dataLoaded?: (resolvedModel: ResolvedModel<M>) => any;
        /** Triggered when the container is done rendering. */
        markEndOfRendering: () => void;
    };
    Blocks: {
        /** Will appear and show a spinner while the `@model` parameter is resolved */
        loading: [WithBoundArgs<typeof PendingContent, 'isPending'>];
        /** Renders after the `@model` is resolved and yields the resolved @model. */
        default: [ResolvedModel<M>];
        /** Renders if the `@model` resolves as empty. */
        'no-results': [
            WithBoundArgs<
                ComponentLike<NoResultsContentSignature>,
                'hasNoResults' | 'search' | 'typeText' | 'markEndOfRendering'
            >
        ];
        /** Renders if an exception is thrown while resolving the `@model`.  */
        error: [WithBoundArgs<ComponentLike<ErrorContentSignature>, 'isRejected' | 'errorText' | 'markEndOfRendering'>];
    };
}

export default class PageViewContainer<M> extends Component<PageViewContainerSignature<M>> {
    @service declare performanceMonitor: PerformanceMonitorService;
    @service declare router: RouterService;
    @service declare intl: ADCIntlService;

    fulfilledComp = FulfilledContent<ResolvedModel<M>>;

    @reads('resolveModel.isRunning')
    declare isPending: boolean;

    @tracked isFulfilled = false;
    @tracked isRejected = false;
    @tracked hasNoResults = false;
    @tracked resolvedModel?: ResolvedModel<M>;
    @tracked errorText = '';

    /**
     * Indicates if the route timing has been reported to the performance monitor.
     */
    wasRouteTimingReported = false;

    /**
     * Records the route URL used when the route timing was reported.
     */
    urlForReportedRouteTiming = '';

    @tracked hasCalledDataLoadedAction = false;

    /**
     * Class name for the content width.
     */
    get contentWidthClass(): ContentWidthClass {
        const { contentWidth } = this.args;
        if (contentWidth) {
            assert(
                '[@adc/ui-components] contentWidth value should be "small" or "large" or "full".',
                CONTENT_WIDTHS.includes(contentWidth)
            );

            return `${contentWidth}-width`;
        }

        return '';
    }

    getResolvedModel = (): ResolvedModel<M> => {
        // This is a hack to tell TS that the resolved model will exist.
        return this.resolvedModel as ResolvedModel<M>;
    };

    resolveModel: Task<void, never> = task({ restartable: true }, async () => {
        Object.assign(this, {
            isFulfilled: false,
            isRejected: false,
            hasNoResults: false,
            resolvedModel: undefined
        });

        // Add a short delay to allow the template to update for the properties set above.
        await timeout(0);

        try {
            const resolvedModel = await this.args.model,
                { router } = this;

            if (!this.wasRouteTimingReported || this.urlForReportedRouteTiming !== router.currentURL) {
                // This model will get resolved any time it changes, but we only want to report route timing once.
                this.wasRouteTimingReported = true;
                this.urlForReportedRouteTiming = router.currentURL;
                this.performanceMonitor.markModelResolved(router.currentRouteName);
            }

            if (resolvedModel) {
                this.args.dataLoaded?.(resolvedModel);
            }

            const hasNoResults =
                !!resolvedModel &&
                ((resolvedModel as { meta?: { totalCount: number } }).meta?.totalCount === 0 ||
                    (isArray(resolvedModel) && resolvedModel.length === 0));

            Object.assign(this, {
                hasCalledDataLoadedAction: true,
                isFulfilled: true,
                hasNoResults,
                resolvedModel
            });
        } catch (ex) {
            Object.assign(this, {
                isRejected: true,
                errorText: (ex as TEmberAjaxError).errors?.[0]?.detail ?? this.intl.t('generic.requestError')
            });
        }
    });
}
