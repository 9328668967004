import { isEmpty, isPresent } from '@ember/utils';
import { isArray, A } from '@ember/array';
import DropdownSelectItem from '../utils/dropdown-select-item.js';
import Helper from '@ember/component/helper';

function convertToDropdownSelectItem(
    customItem: any,
    keys: Record<keyof DropdownSelectItem, string>
): DropdownSelectItem {
    const newItemObject: Partial<DropdownSelectItem> = {};

    Object.entries(keys).forEach(([dropdownSelectKey, customKey]) => {
        // Was a key defined and does the custom object have a value for that key?
        if (customKey && isPresent(customItem[customKey])) {
            newItemObject[dropdownSelectKey] = customItem[customKey];
        }
    });

    return DropdownSelectItem.create(newItemObject);
}

/**
 * Transforms the items resolved in the promise into the correct format for use in the dropdown-select.
 *
 * @note This allows a developer to define a mapping and then have the dropdown-select component handle the
 *       conversion once the promise resolves.
 */
function transformItemsCollection(items: any[], keys: Record<keyof DropdownSelectItem, string>): DropdownSelectItem[] {
    // NOTE: The order of checks is important here. The order of precedence is:
    // 1. Value and name mapping
    // 2. No mapping (return the same array)

    if (!isArray(items) || isEmpty(items)) {
        return [];
    }

    // Is there a known mapping of value and name?
    if (keys.value && keys.name) {
        // Create a DropdownSelectItem for each item returned from the promise.
        return A(items.map((item) => convertToDropdownSelectItem(item, keys)));
    }

    // No mapping was specified. Return the same array.
    return items as DropdownSelectItem[];
}

export interface CreateDropdownItemsSignature {
    Args: {
        Named: { items: any[] | Promise<any[]>; keys: Record<keyof DropdownSelectItem, string> };
    };
    Return: DropdownSelectItem[] | Promise<DropdownSelectItem[]>;
}

/**
 * A template helper for generating a list of dropdown items with specific key/value pairs
 */
export default class CreateDropdownItems extends Helper<CreateDropdownItemsSignature> {
    compute(
        _: [],
        { items, keys }: CreateDropdownItemsSignature['Args']['Named']
    ): CreateDropdownItemsSignature['Return'] {
        return (async () => {
            const transformedItems = transformItemsCollection(await items, keys);
            // Does each item have the correct DropdownSelectItem structure?
            transformedItems.forEach((item) => {
                if (!item || item.name === undefined || item.value === undefined) {
                    throw new Error(`Item must have value and name properties ${JSON.stringify(item)}`);
                }
            });

            return transformedItems;
        })();
    }
}
