import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { computed, action } from '@ember/object';
import { assert } from '@ember/debug';
import { A } from '@ember/array';
import { isEmpty } from '@ember/utils';
import { getModalFooterButton } from '../components/modals/base/footer/modal-footer.ts';
import { isHTMLSafe } from '@ember/template';
import { hasHTML } from '../utils/general.ts';
import InfoDialog from '../components/modals/dialog-modals/info/info-dialog.ts';
import RenameDialog from '../components/modals/confirmations/rename/rename-dialog.ts';
import ConfirmDeleteDialog from '../components/modals/confirmations/delete/confirm-delete.ts';
import LoadingDialog from '../components/modals/loading/loading-spinner.ts';
import PromiseDialog from '../components/modals/dialog-modals/promise/promise-dialog.ts';
import ConfirmationDialog from '../components/modals/dialog-modals/confirm/confirmation-dialog.ts';

import type { ModalButtonConfig } from '../components/modals/base/footer/modal-footer';
import type { SafeString } from 'handlebars';
import type AccessibilityService from './accessibility';
import type ADCIntlService from '@adc/i18n/services/adc-intl';
import type NotificationManagerService from './notification-manager';
import type NativeBridgeService from '@adc/app-infrastructure/services/native-bridge';
import type { NativeButton } from '@adc/app-infrastructure/services/native-bridge';
import type { InfoDialogModel } from '../components/modals/dialog-modals/info/info-dialog';
import type { RenameDialogModel } from '../components/modals/confirmations/rename/rename-dialog';
import type { ConfirmDeleteModel } from '../components/modals/confirmations/delete/confirm-delete';
import type { PromiseDialogModel } from '../components/modals/dialog-modals/promise/promise-dialog';
import type Component from '@glimmer/component';
import type { TOC } from '@ember/component/template-only';
// eslint-disable-next-line ember/no-classic-components
import type EmberComponent from '@ember/component';

/**
 * The private collection of modal objects.
 *
 * @private
 */
const activeModals: ReturnType<typeof A<Modal>> = A<Modal>([]);

export type ModalComponent = TOC<any> | typeof Component<any> | typeof EmberComponent<any>;

export interface ModalModel {
    disableBackdropClose?: boolean;
}

interface VariableModalModal extends ModalModel {
    [key: string]: any;
}

type CloseFn = () => boolean | void;

/**
 * The modal configuration object.
 *
 * @inner
 */
class Modal {
    /**
     * The path to the modal component to render
     */
    type: ModalComponent;

    /**
     * Contains any specific data for a modal by its caller
     */
    @tracked model: VariableModalModal;

    /**
     * An optional handler called when the dialog closes
     */
    onClose: () => boolean | void | undefined;

    /**
     * Modal Constructor
     */
    constructor(type: ModalComponent, model: VariableModalModal = {}, onClose: () => boolean | void = () => true) {
        assert('[@adc/ui-components] Modal object "type" parameter required', !isEmpty(type));

        this.type = type;
        this.model = model;
        this.onClose = onClose;
    }
}

interface ModalNativeButton extends NativeButton {
    css?: string;
}

/**
 * @classdesc
 * Modal Manager service to handle opening and closing of modals
 */
export default class ModalService extends Service {
    @service declare accessibility: AccessibilityService;
    @service declare notificationManager: NotificationManagerService;
    @service declare intl: ADCIntlService;
    @service declare nativeBridge: NativeBridgeService;

    /**
     * Returns readable array of activeModals
     */
    @computed()
    get modalsToRender(): typeof activeModals {
        return activeModals;
    }

    /**
     * Checks if the top-most modal matches the given type
     */
    modalIsOpen(type: ModalComponent): boolean {
        const { modalsToRender } = this;
        return modalsToRender[modalsToRender.length - 1]?.type === type;
    }

    /**
     * Render a confirmation dialog modal.
     */
    async showConfirmationDialog(
        title: string,
        desc: string | SafeString,
        confirmButtonProperties: ModalButtonConfig = {},
        cancelButtonProperties: ModalButtonConfig | null = {},
        cssClass?: string
    ): Promise<void> {
        const { nativeBridge } = this,
            buttons = [
                [confirmButtonProperties, 'confirm', nativeBridge.getButtonTypeConfirm(), () => {}, 'btn-color-primary']
            ];

        if (cancelButtonProperties) {
            buttons.unshift([cancelButtonProperties, 'cancel', nativeBridge.getButtonTypeCancel(), () => {}, '']);
        }

        const nativeButtons = buttons.map(
            ([props, text, nativeButtonType, fn, css]: [
                ModalButtonConfig,
                string,
                string,
                () => unknown,
                string
            ]): ModalNativeButton =>
                ({
                    text: this.intl.t(`@adc/ui-components.${text}`),
                    type: nativeButtonType,
                    action: fn,
                    renderAsNative: true,
                    css,
                    ...props
                } as ModalNativeButton)
        );

        if (nativeBridge.doesSupportConfirmationDialog) {
            try {
                await nativeBridge.showConfirmationDialog(title, String(desc), nativeButtons);
                return;
            } catch (e) {
                // Do nothing
            }
        }

        if (!isHTMLSafe(desc) && hasHTML(desc)) {
            console.warn('The confirmation dialog displayed a description containing HTML characters.');
            assert(
                '[@adc/ui-components] The confirmation dialog displayed a description containing HTML characters.',
                false
            );
        }

        this.showModal(ConfirmationDialog, {
            title,
            description: desc,
            buttons: nativeButtons.map(({ css, text, action }) => getModalFooterButton(text, action, css)),
            cssClass
        });
    }

    /**
     * Opens a new modal dialog, transferring focus and hiding notifications
     */
    @action
    showModal(type: ModalComponent, model?: VariableModalModal, onClose?: CloseFn): void {
        // Hide open notifications and save current focused element.
        this.notificationManager.hideNotifications();
        this.accessibility.saveFocusedElement();

        // Create and display modal
        this.modalsToRender.pushObject(new Modal(type, model, onClose));
    }

    /**
     * Opens a new informational modal dialog, transferring focus and hiding notifications.
     */
    @action
    showInfoModal(model: InfoDialogModel, onClose?: CloseFn): void {
        this.showModal(InfoDialog, model, onClose);
    }

    /**
     * Opens a dialog used to rename things.
     */
    @action
    showRenameModal(model: RenameDialogModel, onClose?: CloseFn): void {
        this.showModal(RenameDialog, model, onClose);
    }

    /**
     * Opens a dialog used to delete things.
     */
    @action
    showDeleteModal(model: ConfirmDeleteModel, onClose?: CloseFn): void {
        this.showModal(ConfirmDeleteDialog, model, onClose);
    }

    /**
     * Render the loading screen
     */
    @action
    showLoadingModal(promise: Promise<any>): void {
        this.showModal(LoadingDialog, { disableBackdropClose: true });

        // Close modal once promise resolves
        promise.finally(() => this.closeModal());
    }

    /**
     * Render a promise dialog.
     */
    @action
    showPromiseModal(model: PromiseDialogModel, onClose?: CloseFn): void {
        this.showModal(
            PromiseDialog,
            {
                ...model,
                disableBackdropClose: true
            },
            onClose
        );
    }

    /**
     * Hides and destroys the last open modal.
     */
    @action
    closeModal(keepNotificationsHidden?: boolean): void {
        const modals = this.modalsToRender;
        let lastModal = modals.lastObject;

        // Abort closing if we should not close
        if (!lastModal || lastModal.onClose() === false) {
            return;
        }

        lastModal = undefined;
        this.modalsToRender.popObject();

        if (!keepNotificationsHidden) {
            this.notificationManager.showNotifications();
        }

        this.accessibility.restoreFocus();
    }
}
