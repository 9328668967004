import Component from '@glimmer/component';
import { action } from '@ember/object';

import type SimpleListItem from './list-item';
import { isEnterOrSpaceCode } from '@adc/ember-utils/utils/a11y';

export interface SimpleListItemSignature {
    Element: HTMLDivElement;
    Args: {
        /** Data for the current item. */
        item: any | SimpleListItem;
        onClick?: VoidFunction;
    };
    Blocks: {
        /** Renders on the left side of the item, typically (but not limited to) an icon. */
        icon: [];
        /** Renders in the center of the item, will render description and details if omitted. */
        detail: [];
        /** Renders to the right of the item, could be an icon, or some value, or really anything. */
        value: [];
    };
}

export default class SimpleListItemComponent extends Component<SimpleListItemSignature> {
    @action
    handleKeyDown(event: KeyboardEvent): void {
        if (isEnterOrSpaceCode(event.code)) {
            this.args.onClick?.();
        }
    }
}
